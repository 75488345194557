









































import Vue from "vue";
import { Form, Field, Checkbox, CheckboxGroup, Button, CellGroup, Cell, PullRefresh, Empty, List, Dialog, DatetimePicker, Popup, Toast, Sticky } from "vant";
import Moment from "moment";
Moment.locale("zh-cn");

export default Vue.extend({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Form.name]: Form,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [Dialog.Component.name]: Dialog.Component,
    [Empty.name]: Empty,
    [List.name]: List,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky
  },
  data() {
    return {
      id: this.$route.params.id || 0,
      plan: {} as ServicePlan.Plan,
      type: this.$route.query.type || "user",
      page: 0,
      size: 10,
      list: [],
      addDialog: {
        show: false,
        showTimePicker: false,
        method: 130,
        time: new Date(),
        money: 0,
        count: 1
      },
      refreshing: false,
      empty: false,
      loading: false,
      loadFinished: false
    };
  },
  methods: {
    submitAddForm() {
      const form = this.$refs.addForm as Form;
      form.submit();
    },
    formatTime(time: Date) {
      return Moment(time).format("lll");
    },
    onRefresh() {
      this.refreshing = true;
      this.page = 0;
      this.list = [];
      this.getList().finally(() => {
        this.refreshing = false;
      });
    },
    getList() {
      const url = "/api/manage/servicePlan/getPayOrderList";
      const data = {
        servicePlanId: this.id,
        page: this.page++,
        size: this.size
      };
      this.loading = true;
      return this.$axios
        .post(url, data)
        .then(res => {
          const d = res.data.data;
          this.plan = d.plan;
          const newList = d.list;
          this.list = this.list.concat(newList);
          return Promise.resolve([res.data.data, data]);
        })
        .then(([data, requestData]) => {
          this.loadFinished = data.list.length < requestData.size;
          this.empty = data.list.length == 0 && requestData.page == 0;
        })
        .catch(() => {
          this.loadFinished = true;
          this.empty = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    add() {
      const data = {
        servicePlanId: this.id,
        money: Math.floor(this.addDialog.money * 100),
        method: this.addDialog.method,
        count: this.addDialog.count,
        time: this.addDialog.time.getTime()
      };
      this.$axios.post("/api/manage/servicePlan/addPayOrder", data).then(() => {
        Toast.success("添加成功");
        this.onRefresh();
      });
    }
  }
});
